import { useReducer, useRef, useEffect } from 'react'
import { produce } from 'immer'

export const useChatMessages = () => {
  const reducer = (state, action) =>
    produce(state, draftState => {
      switch (action.type) {
        case 'ADD':
          draftState.push({
            chatType: action.chatType,
            chatMessage: action.chatMessage,
          })
          break
        default:
          return state
      }
    })

  const [chats, dispatch] = useReducer(reducer, [])

  const addChat = (chatType, chatMessage) => {
    dispatch({ type: 'ADD', chatType, chatMessage })
  }

  const userChatIndex = () => {
    return Object.values(chats).filter(chat => chat.chatType === 'user').length
  }

  return {
    chats,
    addChat,
    userChatIndex: userChatIndex(),
  }
}

export const useTaskQueue = () => {
  const reducer = (state, action) =>
    produce(state, draftState => {
      switch (action.type) {
        case 'ADD':
          draftState.push(action.task)
          break
        case 'UNSET':
          return []
        default:
          return state
      }
    })

  const [tasks, dispatch] = useReducer(reducer, [])

  const addTask = task => {
    dispatch({ type: 'ADD', task })
  }

  const unSetTasks = () => {
    dispatch({ type: 'UNSET' })
  }

  return {
    tasks,
    addTask,
    unSetTasks,
  }
}

export function useTimeOut(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setTimeout(tick, delay)
      return () => clearTimeout(id)
    }
  }, [delay])
}

// Hook
export function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef()

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener
      if (!isSupported) return

      // Create event listener that calls handler function stored in ref
      const eventListener = event => savedHandler.current(event)

      // Add event listener
      element.addEventListener(eventName, eventListener)

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener)
      }
    },
    [eventName, element] // Re-run if eventName or element changes
  )
}
