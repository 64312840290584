import React, { useContext } from 'react'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Message from './Message'
import Input from 'Components/WebChat/Input'
import { WebChatContext } from 'Components/WebChat/WebChat'
import { widgetStyle } from './style'
import ScrollToBottom from 'react-scroll-to-bottom'

const Widget = () => {
  const { chats } = useContext(WebChatContext)
  const classes = widgetStyle()

  return (
    <div data-testid="Widget">
      <Dialog fullScreen open={true}>
        <AppBar className={classes.appBar} color="transparent">
          <Toolbar>
            <img alt="Recii Avatar" src="/reciiAvatar.png" />
            <Typography variant="h5" className={classes.title}>
              Recii
            </Typography>
          </Toolbar>
        </AppBar>
        <ScrollToBottom className={classes.list}>
          {chats &&
            chats.map((chat, index) => <Message key={index} chat={chat} />)}
        </ScrollToBottom>
        <div className={classes.inputDiv}>
          <Input />
        </div>
      </Dialog>
    </div>
  )
}

export default Widget
