import React from 'react'
import { render } from 'react-dom'
import App from './Components/App'
import { Route, Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
export const history = createBrowserHistory()

const target = document.querySelector('#root')
if (module.hot) {
  module.hot.accept()
}

if (process.env.NODE_ENV !== 'production') {
  localStorage.setItem('debug', 'web-chat:*')
}

render(
  <Router history={history}>
    <Switch>
      <Route path="/feedback/:invitationToken" component={App} />
      <Route path="/:invitationToken" component={App} />
    </Switch>
  </Router>,
  target
)
