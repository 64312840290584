import React, { useContext } from 'react'
import Moment from 'react-moment'
import { Button } from '@material-ui/core'
import './style.css'
import { theme } from '../theme'
import { AppContext } from 'Components/App'

const Message = ({ chat }) => {
  const { webSocket } = useContext(AppContext)

  const handleReopen = () => {
    webSocket && webSocket.reconnect()
  }

  const { chatType, chatMessage } = chat

  const reciiMsgStyle = chatType === 'user' ? theme.white : theme.grayAthens
  const msgAlignStyle = chatType === 'user' ? 'auto' : 0

  let text = ''
  let button = false
  let date = null

  if (chatType === 'idleReopen') {
    text = 'I have not heard from you for a while and therefore have closed this chat.'
    button = { text: 'Re-open chat', onClick: handleReopen }
  }

  if (chatMessage) {
    const {
      dialogue,
      timestamp,
      transcript: { result },
    } = chatMessage

    text = result ? result : dialogue['content'] ? dialogue['content'] : ''
    date = timestamp
  }

  return (
    <div
      className="messageContainer"
      style={{ backgroundColor: reciiMsgStyle, marginLeft: msgAlignStyle }}
      data-testid="Message">
      <div className="inputText">{text}</div>
      {button && (
        <div className="inputText">
          <Button
            variant="outlined"
            theme="primary"
            text={button.text}
            onClick={button.onClick}>
            {button.text}
          </Button>
        </div>
      )}
      {date && (
        <div className="inputDate">
          <Moment format="ddd | HH:mm" date={date} />
        </div>
      )}
    </div>
  )
}

export default Message
