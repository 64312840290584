const second = 1000
const minute = second * 60
export const timerUnit = minute

export const debugEnabled = true

// Outbound - what DM sends
// Inbound - What client sends back to DM
export const WSError = 0 // Outbound. DialogueManager error
export const WSInitialise = 1 // Outbound. Successfully connected to DM. Usually first message.
export const WSResponse = 2 // Inbound. Client should use this code when sending user message to DM.
export const WSText = 3 // Outbound. Indicates that message is of the text type. This usually be next question.
export const WSBinary = 4 // Outbound. Reserved for future non-textual messages (multimedia)
export const WSMoveForward = 5 // Inbound. Client can send this message to finish current question and move forward with conversation
export const WSGreeting = 6 // Outbound. Typically "Hello I'm Ali". Client decides whether to show this type of messages.
export const WSEndConversation = 7 // Outbound. DM requested to close websocket connection and client should terminate web-socket connection with DM
export const WSSmallTalk = 8 // Outbound. Small talk, i.e. 'Jotting that down' client decides whether to display or not.
export const WSThankyou = 9 // Outbound. End of chat thank you message. Client decides whether to show message to user or not.
export const WSConflict = 10 // Outbound. Channel conflict. User requested to carry conversation on a different channel
