import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import ReconnectingWebSocket from 'reconnecting-websocket'
import WebChat from 'Components/WebChat/WebChat'
import logger from 'Components/logger'
import axios from 'axios'

export const AppContext = React.createContext({
  invite: { id: null },
})

const App = ({
  match: {
    params: { invitationToken },
  },
}) => {
  const [invite, setInvite] = useState({
    id: null,
  })
  const [webSocket, setWebSocket] = useState(null)

  useEffect(() => {
    const fetchInvitationToken = async () => {
      try {
        axios
          .get(`${window.API_URL}/invitations/verify/${invitationToken}`, {
            headers: {
              Accept: 'application/json',
            },
          })
          .then(result => {
            setInvite(result.data)
          })
          .catch(error => logger.info('an error occurred. ', error))
      } catch (e) {
        logger.info(e)
      }
    }

    fetchInvitationToken().then()
  }, [invitationToken])

  useEffect(() => {
    const { id: inviteId } = invite

    if (inviteId) {
      const webSocket = new ReconnectingWebSocket(
        `${window.DIALOGUE_MANAGER}/socket/calls/${inviteId}/conversation/${window.CHANNEL_ID}`,
        [],
        {
          maxRetries: 10,
        }
      )
      setWebSocket(webSocket)

      return () => {
        webSocket.close()
      }
    }
  }, [invite])

  return (
    <div className="App" data-testid="App">
      <AppContext.Provider
        value={{
          invite,
          webSocket,
        }}>
        <WebChat />
      </AppContext.Provider>
    </div>
  )
}

export default withRouter(App)
