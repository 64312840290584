import React, { useContext, useState, useRef } from 'react'
import Widget from './Widget'
import { produce } from 'immer'
import moment from 'moment'
import { useChatMessages, useTaskQueue, useEventListener } from './base'
import logger from 'Components/logger'
import {
  WSConflict,
  WSEndConversation,
  WSGreeting,
  WSSmallTalk,
} from 'config/config'
import { AppContext } from 'Components/App'
export const WebChatContext = React.createContext({})

const WebChat = () => {
  /* CONTEXT */
  const { webSocket } = useContext(AppContext)

  /* HOOKS */
  const { chats, addChat, userChatIndex } = useChatMessages()

  const { tasks, addTask, unSetTasks } = useTaskQueue()
  const [inputDisabled, setInputDisabled] = useState(false)

  const [completeDelay, setCompleteDelay] = useState(null)

  /* REFS */
  const connectionInfo = useRef({
    timeDiff: null,
    connectionID: null,
  })

  /* METHODS */
  const sendMessage = message => {
    const { timeDiff, connectionID } = connectionInfo.current

    const currentTime = moment(Date.now())
    const updatedTimestamp = moment(currentTime - timeDiff).toISOString()

    const updatedMessage = produce(message, draftState => {
      draftState.referrer = window.CHANNEL_ID
      draftState.connectionID = connectionID
      draftState.timestamp = updatedTimestamp
    })

    webSocket.send(JSON.stringify([updatedMessage]))
    logger.info('send message: ', updatedMessage)
  }

  const handleOpen = () => {
    setInputDisabled(false)
    logger.info('connected')
  }

  const handleClose = event => {
    logger.info('disconnected', event)
  }

  const handleError = event => {
    logger.error('websocket error', event)
  }

  // handle messages based on code
  const handleMessage = chatMessage => {
    const { code, timestamp, connectionID } = chatMessage

    if (code === WSGreeting) {
      const currentTime = moment(Date.now())
      const timeDiff = moment(currentTime.diff(timestamp))

      connectionInfo.current = {
        timeDiff,
        connectionID,
      }
    }

    if ([WSGreeting, WSSmallTalk, WSConflict].includes(code)) {
      setCompleteDelay(null)
      sendMessage(chatMessage)
      addChat('server', chatMessage)
      handleCloseWebSocket(code)
    } else {
      addTask(chatMessage)
    }
  }

  // handle messages received from dialogue manager
  const handleCloseUserMessage = message => {
    sendMessage(message)

    tasks.forEach(task => {
      sendMessage(task)
      addChat('server', task)
      handleCloseWebSocket(task.code)
    })

    unSetTasks()
  }

  const handleCloseWebSocket = code => {
    if ([WSEndConversation, WSConflict].includes(code)) {
      setInputDisabled(true)
      webSocket && webSocket.close()
    }
  }

  const handleNewUserMessage = message => {
    sendMessage(message)
    addChat('user', message)
  }

  // handle messages received from dialogue manager
  const handleServerMessage = ({ data }) => {
    unSetTasks()

    const chatMessages = JSON.parse(data)
    chatMessages.forEach(chatMessage => handleMessage(chatMessage))

    logger.info(chatMessages)
  }

  useEventListener('open', handleOpen, webSocket)
  useEventListener('close', handleClose, webSocket)
  useEventListener('message', handleServerMessage, webSocket)
  useEventListener('error', handleError, webSocket)

  return (
    <WebChatContext.Provider
      value={{
        chats,
        addChat,
        inputDisabled,
        setInputDisabled,
        handleCloseUserMessage,
        handleNewUserMessage,
        userChatIndex,
        completeDelay,
        setCompleteDelay

      }}>
      <Widget />
    </WebChatContext.Provider>
  )
}

export default WebChat
