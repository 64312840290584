import { theme } from '../theme'
import { createStyles, makeStyles } from '@material-ui/core/styles'

export const widgetStyle = makeStyles(styleTheme =>
  createStyles({
    appBar: {
      position: 'sticky',
      boxShadow: 'none',
      backgroundColor: theme.white,
    },
    title: {
      flex: 1,
      fontWeight: 'normal',
    },
    inputDiv: {
      bottom: 0,
      position: 'sticky',
      width: '100%',
      backgroundColor: theme.white,
      borderTop: `1px solid ${theme.grayComment}`,
    },
    list: {
      height: '100%',
      padding: '0 24px 8px 16px',
      overflow: 'scroll',
    },
  })
)

export const inputStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  input: {
    color: theme.blackText,
    fontSize: '16px',
    fontFamily: 'Roboto, sans-serif',
    padding: '20px',
  },
})
