export const theme = {
  primaryColorValencia: '#D63948',
  primaryColorValenciaDark: '#BD202F',
  primaryColorValenciaLight: '#D86C75',
  white: '#ffffff',
  black: '#000000',
  grayComment: '#5B637A', // darkest
  grayCommentDark: '#474e68',
  pickledBlueWood: '#313950',
  grayGeyser: '#DCE1E8',
  grayAthens: '#F6F7F9', // lightest
  blackText: 'rgba(0,0,0,0.87)',
  Gray: '#BBBBBB',
  secondaryBlue: '#00ACC1',
  secondaryGray: '#AAAAAA',
  secondaryYellow: '#F9A825',
}
