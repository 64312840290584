import React, { useState, useContext, useEffect } from 'react'
import { timerUnit, WSMoveForward, WSResponse } from 'config/config'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SendIcon from '@material-ui/icons/Send'
import { useTimeOut } from 'Components/WebChat/base'
import { AppContext } from 'Components/App'
import { WebChatContext } from 'Components/WebChat/WebChat'
import { inputStyles } from './style'

const Input = () => {
  const classes = inputStyles()

  /* CONTEXTS */
  const {
    invite: { id: inviteId },
    webSocket,
  } = useContext(AppContext)

  const {
    inputDisabled,
    setInputDisabled,
    addChat,
    handleNewUserMessage,
    handleCloseUserMessage,
    userChatIndex,
    completeDelay,
    setCompleteDelay
  } = useContext(WebChatContext)

  /* HOOKS */
  const [inputText, setInputText] = useState('')
  const [closeDelay, setCloseDelay] = useState(null)

  /* SIDE EFFECTS */
  // TimeOut to send answer complete action
  useTimeOut(() => {
    const closeMessage = {
      invitationID: inviteId,
      agentInfo: navigator.userAgent,
      referrer: window.CHANNEL_ID,
      messageType: 1,
      code: WSMoveForward,
    }

    handleCloseUserMessage(closeMessage)
  }, completeDelay)

  // TimeOut to close connection
  useTimeOut(() => {
    // state above 1 is closed or closing
    if (webSocket.readyState < 2) {
      addChat('idleReopen')
      setInputDisabled(true)
      webSocket && webSocket.close()
    }
  }, closeDelay)

  useEffect(() => {
    webSocket && setCloseDelay(timerUnit * 5)
  }, [webSocket])

  /* METHODS */
  // Handle message input from user and send to dialogue manager
  const onSend = () => {
    if (inputText !== '') {
      const userMessage = {
        invitationID: inviteId,
        agentInfo: navigator.userAgent,
        referrer: window.CHANNEL_ID,
        messageType: 1,
        code: WSResponse,
        transcript: {
          idx: userChatIndex,
          result: inputText,
        },
      }

      handleNewUserMessage(userMessage)

      setInputText('')
      setCompleteDelay(3000)
    }
  }

  const onKeyUp = event => {
    setCloseDelay(timerUnit * 5)
    if (event.key !== 'Enter' && inputText === '') {
      setCompleteDelay(3000)
    } else if (event.key !== 'Enter') {
      setCompleteDelay(null)
    }
  }

  const onKeyDown = event => {
    setCloseDelay(null)
    if (event.key === 'Enter') {
      onSend()
    }
  }

  const onChange = event => {
    setInputText(event.target.value)
  }

  return (
    <TextField
      data-testid="Input"
      fullWidth={true}
      variant="standard"
      value={inputText}
      onChange={onChange}
      disabled={inputDisabled}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      placeholder="Type a message"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" style={{ paddingRight: '16px' }}>
            <SendIcon onClick={onSend} data-testid="Send" />
          </InputAdornment>
        ),
        classes,
      }}
    />
  )
}

export default Input
